html, body, div, span, applet, object, iframe, h1, h2,
h3, h4, h5, h6, p, blockquote, a, abbr, acronym, address,
big, cite, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, input,
textarea, menu, button, nav, output, ruby, section, summary,
time, mark, audio, video {
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  font-size: 100%;
  font-weight: normal;
  font-family: inherit;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box; }

input::-ms-clear {
  display: none; }

body {
  font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto";
  color: #444;
  font-size: 16px; }

h1 {
  font-size: 1.8125rem; }

h2 {
  font-size: 1.625rem; }

h3 {
  font-size: 1.5rem; }

h4 {
  font-size: 1.375rem; }

h5 {
  font-size: 1.25rem; }

h6 {
  font-size: 1rem; }

html, body, #app, .aur-application-wrapper {
  height: 100%;
  background-color: #F7F8F9; }

.aur-auto-complete-dropdown .Select-control {
  border: 1px solid #ddd;
  border-radius: 2px;
  height: 40px;
  width: 100%;
  max-width: 100%;
  display: flex; }

.aur-auto-complete-dropdown > .Select-control .Select-value,
.aur-auto-complete-dropdown .Select-multi-value-wrapper,
.aur-auto-complete-dropdown .Select-value-label,
.aur-auto-complete-dropdown .Select-placeholder {
  color: #444;
  height: 40px;
  line-height: 40px;
  vertical-align: baseline;
  max-width: calc((100% - 20px) - (2 * 10px));
  min-width: calc((100% - 20px) - (2 * 10px)); }

.aur-auto-complete-dropdown .Select-placeholder {
  color: #888; }

.aur-auto-complete-dropdown .Select-input {
  color: #444;
  height: 40px;
  line-height: 40px;
  vertical-align: baseline;
  padding: 0;
  max-width: 100%;
  min-width: 100%; }

.aur-auto-complete-dropdown .Select-input > input {
  color: #444;
  height: 40px;
  vertical-align: baseline;
  box-sizing: border-box;
  padding: 0 10px;
  max-width: calc(100% - 20px); }

.aur-auto-complete-dropdown .Select-arrow-zone {
  flex: 0 0 40px;
  border-left: 1px solid #ddd;
  padding: 10px 14.5px;
  margin: 0;
  height: 40px; }

.aur-auto-complete-dropdown span.Select-arrow {
  border: none; }

.aur-auto-complete-dropdown .Select-menu-outer {
  margin-top: 0;
  margin-bottom: 96px;
  width: 100%;
  max-width: 100%;
  max-height: 320px; }

.aur-auto-complete-dropdown .Select-menu {
  max-height: 318px; }

.aur-auto-complete-dropdown .Select-option {
  color: #444; }

.aur-auto-complete-dropdown .Select-option.is-focused {
  background-color: #dcf8ff;
  color: #444; }

.aur-auto-complete-dropdown .Select-option.is-selected:not(.is-focused) {
  background-color: white; }

.is-focused:not(.is-open) > .Select-control,
.is-focused > .Select-control {
  border: 1px solid #1397f4; }
  .is-focused:not(.is-open) > .Select-control .Select-placeholder,
  .is-focused > .Select-control .Select-placeholder {
    display: none; }

.aur-button {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  border: 1px solid;
  height: 40px;
  line-height: 100%;
  padding-left: 21px;
  padding-right: 21px; }
  .aur-button.primary {
    background-color: #1397f4;
    border-color: #1397f4;
    color: white; }
  .aur-button.secondary {
    background-color: white;
    border-color: #1397f4;
    color: #1397f4; }

.aur-calendar-icon {
  color: #444; }

.aur-drop-down-icon {
  font-size: 16px;
  color: #888; }

.aur-error-message {
  color: #f83155;
  font-size: 14px; }

.aur-keys-icon sub {
  font-size: .6em;
  position: relative;
  left: .5em;
  bottom: .15em; }

.aur-keys-icon sup {
  font-size: .6em;
  position: relative;
  left: -.5em;
  top: .15em; }

.aur-label, .aur-label-fixed {
  color: #444;
  text-transform: capitalize; }
  .aur-label-fixed {
    display: inline-block; }

.aur-link {
  font: inherit;
  color: #1397f4;
  text-decoration: none;
  text-transform: capitalize; }
  .aur-link:hover {
    cursor: pointer; }

.aur-page-body {
  display: flex;
  flex-direction: column;
  flex: 1; }

.aur-page-footer-wrapper {
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 24px;
  box-shadow: 0 -2px 4px #e5e5e5;
  display: flex;
  height: 60px;
  justify-content: flex-end;
  left: 0;
  max-height: 60px;
  padding: 12px 0 12px 0;
  position: fixed;
  right: 0;
  z-index: 99999999; }
  .aur-page-footer-wrapper .aur-page-footer-item {
    padding: 0 12px 0 12px; }

.aur-procedure-icon {
  font-size: 16px; }

.aur-required-icon {
  color: #f83155;
  vertical-align: super;
  font-size: smaller;
  font-weight: bold; }

.aur-splash {
  width: 100%;
  color: #888;
  text-align: center;
  margin-top: 2em; }
  .aur-splash .aur-splash-icon {
    font-size: 1.8125rem;
    margin-bottom: 16px; }

.aur-text-area, .aur-text-area-error {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  resize: none;
  width: 100%;
  padding: 12px;
  color: #444;
  border: 1px solid #ddd; }
  .aur-text-area::-webkit-input-placeholder, .aur-text-area-error::-webkit-input-placeholder {
    color: #888; }
  .aur-text-area:-moz-placeholder, .aur-text-area-error:-moz-placeholder {
    color: #888; }
  .aur-text-area::-moz-placeholder, .aur-text-area-error::-moz-placeholder {
    color: #888; }
  .aur-text-area:-ms-input-placeholder, .aur-text-area-error:-ms-input-placeholder {
    color: #888; }
  .aur-text-area-error {
    border-color: #f83155;
    background-color: #fff5f7; }
    .aur-text-area-error::-webkit-input-placeholder {
      color: #fff5f7; }
    .aur-text-area-error:-moz-placeholder {
      color: #fff5f7; }
    .aur-text-area-error::-moz-placeholder {
      color: #fff5f7; }
    .aur-text-area-error:-ms-input-placeholder {
      color: #fff5f7; }
  .aur-text-area:focus, .aur-text-area-error:focus {
    border: 1px solid #1397f4; }
    .aur-text-area:focus::-webkit-input-placeholder, .aur-text-area-error:focus::-webkit-input-placeholder {
      color: white; }
    .aur-text-area:focus:-moz-placeholder, .aur-text-area-error:focus:-moz-placeholder {
      color: white; }
    .aur-text-area:focus::-moz-placeholder, .aur-text-area-error:focus::-moz-placeholder {
      color: white; }
    .aur-text-area:focus:-ms-input-placeholder, .aur-text-area-error:focus:-ms-input-placeholder {
      color: white; }

.aur-text-box, .aur-text-box-error {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  padding: 11px 12px 11px 12px;
  color: #444;
  border: 1px solid #ddd;
  width: 100%;
  height: 40px; }
  .aur-text-box::-webkit-input-placeholder, .aur-text-box-error::-webkit-input-placeholder {
    color: #888; }
  .aur-text-box:-moz-placeholder, .aur-text-box-error:-moz-placeholder {
    color: #888; }
  .aur-text-box::-moz-placeholder, .aur-text-box-error::-moz-placeholder {
    color: #888; }
  .aur-text-box:-ms-input-placeholder, .aur-text-box-error:-ms-input-placeholder {
    color: #888; }
  .aur-text-box:focus, .aur-text-box-error:focus {
    border: 1px solid #1397f4; }
    .aur-text-box:focus::-webkit-input-placeholder, .aur-text-box-error:focus::-webkit-input-placeholder {
      color: white; }
    .aur-text-box:focus:-moz-placeholder, .aur-text-box-error:focus:-moz-placeholder {
      color: white; }
    .aur-text-box:focus::-moz-placeholder, .aur-text-box-error:focus::-moz-placeholder {
      color: white; }
    .aur-text-box:focus:-ms-input-placeholder, .aur-text-box-error:focus:-ms-input-placeholder {
      color: white; }
  .aur-text-box-error {
    border-color: #f83155;
    background-color: #fff5f7; }
    .aur-text-box-error::-webkit-input-placeholder {
      color: #fff5f7; }
    .aur-text-box-error:-moz-placeholder {
      color: #fff5f7; }
    .aur-text-box-error::-moz-placeholder {
      color: #fff5f7; }
    .aur-text-box-error:-ms-input-placeholder {
      color: #fff5f7; }
    .aur-text-box-error:focus {
      border-color: #f83155; }
      .aur-text-box-error:focus::-webkit-input-placeholder {
        color: #fff5f7; }
      .aur-text-box-error:focus:-moz-placeholder {
        color: #fff5f7; }
      .aur-text-box-error:focus::-moz-placeholder {
        color: #fff5f7; }
      .aur-text-box-error:focus:-ms-input-placeholder {
        color: #fff5f7; }

.aur-application-footer {
  background-color: #444;
  color: white;
  font-size: 12px;
  padding: 0 20px 0 20px;
  height: 24px;
  max-height: 24px;
  white-space: nowrap;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999999; }
  .aur-application-footer img {
    height: 12px;
    width: auto; }
  .aur-application-footer .aur-application-footer-item {
    padding-right: 20px;
    line-height: 24px;
    vertical-align: middle; }

.aur-application-header {
  background-color: #00609c;
  height: 54px;
  width: 100%;
  display: flex;
  align-items: center; }

.aur-client-header-logo-container {
  padding-left: 20px;
  height: 25px; }
  .aur-client-header-logo-container img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto; }

.aur-username-container {
  padding-right: 20px;
  color: #FFFFFF;
  margin-left: auto; }

.aur-card {
  border: 1px solid #ddd;
  margin-bottom: 20px;
  background-color: white;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px; }
  @media (max-width: 1023px) {
    .aur-card {
      margin-bottom: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      border-radius: 0; }
      .aur-card:not(:last-of-type) {
        border-bottom: none; } }
  .aur-card-header {
    border-bottom: 4px solid #00609c;
    height: 55px;
    display: flex; }
    .aur-card-header-icon {
      font-size: 1.25rem;
      color: #00609c;
      padding: 18.33333px 20px; }
    .aur-card-header-title {
      min-width: 0;
      font-size: 1.25rem;
      text-transform: capitalize;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      flex-grow: 1;
      padding: 18.33333px 0; }
    .aur-card-header-collapse-icon {
      font-size: 1.25rem;
      color: #444;
      padding: 18.33333px 20px; }
  .aur-card-body {
    margin: 20px 12.5% 20px 20px; }

.aur-date-picker > div > div:first-child {
  width: 100%; }

.react-datepicker {
  font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto";
  color: #444;
  border: 1px solid #ddd;
  border-radius: 2px; }

.react-datepicker__tether-element-attached-top .react-datepicker__triangle {
  border-bottom-color: white; }

.react-datepicker__header {
  background-color: white;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px; }

.react-datepicker__current-month {
  color: #444;
  padding-bottom: 6px;
  font-size: 1rem;
  font-weight: normal; }

.react-datepicker__navigation {
  font-family: FontAwesome;
  font-size: 12px;
  color: #444;
  border: none;
  top: 6px; }
  .react-datepicker__navigation--previous {
    left: 15px; }
    .react-datepicker__navigation--previous:before {
      content: ""; }
    .react-datepicker__navigation--previous:hover {
      color: #2b2b2b; }
  .react-datepicker__navigation--next {
    right: 15px; }
    .react-datepicker__navigation--next:before {
      content: ""; }
    .react-datepicker__navigation--next:hover {
      color: #2b2b2b; }
  .react-datepicker__navigation--years-previous {
    border-top-color: #444; }
    .react-datepicker__navigation--years-previous:hover {
      border-top-color: #2b2b2b; }
  .react-datepicker__navigation--years-upcoming {
    border-bottom-color: #444; }
    .react-datepicker__navigation--years-upcoming:hover {
      border-bottom-color: #2b2b2b; }

.react-datepicker__month-container {
  border: none; }

.react-datepicker__day-name,
.react-datepicker__day {
  color: #444; }

.react-datepicker__day-name {
  color: #1397f4; }

.react-datepicker__day:hover {
  border-radius: 2px;
  background-color: #a9eeff;
  color: #444; }

.react-datepicker__day--highlighted {
  border-radius: 2px;
  background-color: #5ed255; }
  .react-datepicker__day--highlighted:hover {
    background-color: #4bcd41; }

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
  border-radius: 2px;
  background-color: #1397f4;
  color: white; }
  .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover {
    background-color: #0b8ae3; }

.react-datepicker__day--keyboard-selected {
  border-radius: 2px;
  background-color: #a9eeff; }
  .react-datepicker__day--keyboard-selected:hover {
    background-color: #c3f3ff; }

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background-color: rgba(19, 151, 244, 0.5); }

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
  background-color: white;
  color: #444; }

.react-datepicker__day--outside-month {
  color: #888; }

.react-datepicker__day--disabled {
  color: #a2a2a2; }

.react-datepicker__input-container {
  width: 100%; }
  .react-datepicker__input-container > input {
    border: 1px solid #ddd;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    border-radius: 2px;
    font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto";
    font-size: 16px;
    color: #444;
    width: 100%;
    height: 100%;
    padding: 12px; }
    .react-datepicker__input-container > input:focus {
      border: 1px solid #1397f4; }

.react-datepicker__year-read-view {
  text-align: left;
  display: flex;
  justify-content: space-around;
  border: 1px solid #ddd;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px; }
  .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow {
    border-top-color: #6f6f6f; }
  .react-datepicker__year-read-view--selected-year {
    flex-grow: 1;
    padding: 3px 6px;
    text-overflow: ellipsis; }
  .react-datepicker__year-read-view--down-arrow {
    font-family: FontAwesome;
    font-size: 12px;
    color: #444;
    border: none;
    float: none;
    width: 21px;
    height: 100%;
    border-left: 1px solid #ddd;
    padding: 3px 6px;
    margin: 0;
    order: 9; }
    .react-datepicker__year-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow:before {
      position: static;
      z-index: 0;
      box-sizing: border-box; }
    .react-datepicker__year-read-view--down-arrow:before {
      border: none;
      content: ""; }

.react-datepicker__month-read-view {
  text-align: left;
  display: flex;
  border: 1px solid #ddd;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px; }
  .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow {
    border-top-color: #6f6f6f; }
  .react-datepicker__month-read-view--selected-month {
    flex-grow: 1;
    padding: 3px 6px;
    width: 75px;
    text-overflow: ellipsis; }
  .react-datepicker__month-read-view--down-arrow {
    font-family: FontAwesome;
    font-size: 12px;
    color: #444;
    border: none;
    float: none;
    width: 21px;
    height: 100%;
    border-left: 1px solid #ddd;
    padding: 3px 6px;
    margin: 0;
    order: 9; }
    .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow:before {
      position: static;
      z-index: 0;
      box-sizing: border-box; }
    .react-datepicker__month-read-view--down-arrow:before {
      border: none;
      content: ""; }

.react-datepicker__year-read-view--selected-year,
.react-datepicker__month-read-view--selected-month {
  color: #444; }

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown {
  background-color: white;
  border-radius: 2px;
  border: 1px solid #ddd; }

.react-datepicker__today-button {
  background: white;
  border-top: 1px solid #ddd;
  color: #1397f4;
  padding: 6px 0; }

.aur-form-element {
  margin-bottom: 14px; }
  .aur-form-element-field {
    margin-top: 8px;
    margin-bottom: 1px; }
  .aur-form-element-error {
    height: 16px; }
  .aur-form-element-error-icon {
    color: #f83155;
    font-size: 14px;
    margin-right: 2px; }

.aur-form-legend {
  width: 100%;
  text-align: right;
  position: relative;
  right: -7.25%;
  color: #888;
  font-size: 1rem; }

.aur-grid-wrapper {
  padding: 20px; }

.aur-header {
  width: 100%;
  height: 100%; }
  .aur-header > .aur-grid-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%; }
    .aur-header > .aur-grid-header > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 0; }
    .aur-header > .aur-grid-header > i {
      flex: 0 0;
      vertical-align: middle; }

.aur-inline-fields {
  display: flex;
  background-color: white;
  padding-left: 20px;
  padding-right: 20px; }

.aur-inline-fields-element {
  margin-right: 10px;
  flex-shrink: 0; }

.aur-navigation-tab-list {
  background-color: white;
  display: flex;
  height: 60px;
  width: 100%;
  align-items: center;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .aur-navigation-tab-list .aur-navigation-tab {
    color: #888;
    cursor: pointer;
    display: inline-block;
    flex-shrink: 0;
    font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto";
    font-size: 16px;
    margin: 0 24px;
    padding: 4px 2px;
    text-decoration: none;
    border-bottom: 3px solid transparent; }
    .aur-navigation-tab-list .aur-navigation-tab:hover {
      color: #444; }
    .aur-navigation-tab-list .aur-navigation-tab.active-tab {
      border-bottom: 3px solid #1397f4;
      color: #1397f4;
      cursor: default;
      pointer-events: none; }

.aur-pagination-controls {
  background-color: white;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px 25%;
  font-size: 12px; }
  .aur-pagination-controls .aur-pagination-icon {
    color: #1397f4;
    width: 25px;
    height: 25px;
    line-height: 25px;
    padding: 0px;
    text-align: center;
    font-size: 16px; }
  .aur-pagination-controls .aur-pagination-label {
    text-transform: none;
    color: #444; }

.aur-tabbed-panel .aur-tabbed-panel-tabs {
  background-color: white;
  display: flex;
  padding-left: 48px;
  border-bottom: 1px solid #ddd; }
  .aur-tabbed-panel .aur-tabbed-panel-tabs .aur-tabbed-panel-tab {
    padding: 8px 16px;
    color: #888;
    text-decoration: none;
    border-top: 2px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid #ddd;
    position: relative;
    top: 1px; }
    .aur-tabbed-panel .aur-tabbed-panel-tabs .aur-tabbed-panel-tab:hover {
      color: #1397f4; }
    .aur-tabbed-panel .aur-tabbed-panel-tabs .aur-tabbed-panel-tab.active {
      background-color: #F7F8F9;
      border-top: 2px solid #1397f4;
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #F7F8F9;
      color: #444; }

.aur-adaptive-column-layout-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  padding-bottom: 96px; }
  @media (max-width: 1023px) {
    .aur-adaptive-column-layout-wrapper {
      display: block; } }
  .aur-adaptive-column-layout-wrapper .aur-adaptive-column-layout-item {
    flex-grow: 0;
    margin: 0 10px; }
    .aur-adaptive-column-layout-wrapper .aur-adaptive-column-layout-item.columns-1 {
      min-width: 923px;
      flex-basis: calc(((100% - 60px) / 1) + 40px); }
    @media (max-width: 1023px) {
      .aur-adaptive-column-layout-wrapper .aur-adaptive-column-layout-item {
        max-width: none;
        margin-left: 0;
        margin-right: 0;
        flex: 0 1 auto; } }
    .aur-adaptive-column-layout-wrapper .aur-adaptive-column-layout-item.columns-2 {
      min-width: 451.5px;
      flex-basis: calc(((100% - 80px) / 2) + 20px); }
    @media (max-width: 1023px) {
      .aur-adaptive-column-layout-wrapper .aur-adaptive-column-layout-item {
        max-width: none;
        margin-left: 0;
        margin-right: 0;
        flex: 0 1 auto; } }
    .aur-adaptive-column-layout-wrapper .aur-adaptive-column-layout-item.columns-3 {
      min-width: 294.33333px;
      flex-basis: calc(((100% - 100px) / 3) + 13.33333px); }
    @media (max-width: 1023px) {
      .aur-adaptive-column-layout-wrapper .aur-adaptive-column-layout-item {
        max-width: none;
        margin-left: 0;
        margin-right: 0;
        flex: 0 1 auto; } }
    .aur-adaptive-column-layout-wrapper .aur-adaptive-column-layout-item.columns-4 {
      min-width: 215.75px;
      flex-basis: calc(((100% - 120px) / 4) + 10px); }
    @media (max-width: 1023px) {
      .aur-adaptive-column-layout-wrapper .aur-adaptive-column-layout-item {
        max-width: none;
        margin-left: 0;
        margin-right: 0;
        flex: 0 1 auto; } }
    .aur-adaptive-column-layout-wrapper .aur-adaptive-column-layout-item.columns-5 {
      min-width: 168.6px;
      flex-basis: calc(((100% - 140px) / 5) + 8px); }
    @media (max-width: 1023px) {
      .aur-adaptive-column-layout-wrapper .aur-adaptive-column-layout-item {
        max-width: none;
        margin-left: 0;
        margin-right: 0;
        flex: 0 1 auto; } }
    .aur-adaptive-column-layout-wrapper .aur-adaptive-column-layout-item.columns-6 {
      min-width: 137.16667px;
      flex-basis: calc(((100% - 160px) / 6) + 6.66667px); }
    @media (max-width: 1023px) {
      .aur-adaptive-column-layout-wrapper .aur-adaptive-column-layout-item {
        max-width: none;
        margin-left: 0;
        margin-right: 0;
        flex: 0 1 auto; } }
    .aur-adaptive-column-layout-wrapper .aur-adaptive-column-layout-item.columns-7 {
      min-width: 114.71429px;
      flex-basis: calc(((100% - 180px) / 7) + 5.71429px); }
    @media (max-width: 1023px) {
      .aur-adaptive-column-layout-wrapper .aur-adaptive-column-layout-item {
        max-width: none;
        margin-left: 0;
        margin-right: 0;
        flex: 0 1 auto; } }
    .aur-adaptive-column-layout-wrapper .aur-adaptive-column-layout-item.columns-8 {
      min-width: 97.875px;
      flex-basis: calc(((100% - 200px) / 8) + 5px); }
    @media (max-width: 1023px) {
      .aur-adaptive-column-layout-wrapper .aur-adaptive-column-layout-item {
        max-width: none;
        margin-left: 0;
        margin-right: 0;
        flex: 0 1 auto; } }
    .aur-adaptive-column-layout-wrapper .aur-adaptive-column-layout-item.columns-9 {
      min-width: 84.77778px;
      flex-basis: calc(((100% - 220px) / 9) + 4.44444px); }
    @media (max-width: 1023px) {
      .aur-adaptive-column-layout-wrapper .aur-adaptive-column-layout-item {
        max-width: none;
        margin-left: 0;
        margin-right: 0;
        flex: 0 1 auto; } }
    .aur-adaptive-column-layout-wrapper .aur-adaptive-column-layout-item.columns-10 {
      min-width: 74.3px;
      flex-basis: calc(((100% - 240px) / 10) + 4px); }
    @media (max-width: 1023px) {
      .aur-adaptive-column-layout-wrapper .aur-adaptive-column-layout-item {
        max-width: none;
        margin-left: 0;
        margin-right: 0;
        flex: 0 1 auto; } }
    .aur-adaptive-column-layout-wrapper .aur-adaptive-column-layout-item.columns-11 {
      min-width: 65.72727px;
      flex-basis: calc(((100% - 260px) / 11) + 3.63636px); }
    @media (max-width: 1023px) {
      .aur-adaptive-column-layout-wrapper .aur-adaptive-column-layout-item {
        max-width: none;
        margin-left: 0;
        margin-right: 0;
        flex: 0 1 auto; } }
    .aur-adaptive-column-layout-wrapper .aur-adaptive-column-layout-item.columns-12 {
      min-width: 58.58333px;
      flex-basis: calc(((100% - 280px) / 12) + 3.33333px); }
    @media (max-width: 1023px) {
      .aur-adaptive-column-layout-wrapper .aur-adaptive-column-layout-item {
        max-width: none;
        margin-left: 0;
        margin-right: 0;
        flex: 0 1 auto; } }

.aur-application-body {
  height: calc(100% - 78px)-60px;
  display: flex;
  flex-direction: column; }
  @media (max-width: 1023px) {
    .aur-application-body {
      margin-left: 0;
      margin-right: 0; } }

ag-grid-angular {
  display: inline-block; }

ag-grid-ng2 {
  display: inline-block; }

ag-grid {
  display: block; }

ag-grid-polymer {
  display: block; }

ag-grid-aurelia {
  display: block; }

.ag-rtl {
  direction: rtl; }

.ag-ltr {
  direction: ltr; }

.ag-select-agg-func-popup {
  position: absolute; }

.ag-body-no-select {
  user-select: none; }

.ag-root {
  /* set to relative, so absolute popups appear relative to this */
  position: relative;
  box-sizing: border-box;
  /* was getting some 'should be there' scrolls, this sorts it out */
  overflow: hidden; }

.ag-layout-normal .ag-root {
  height: 100%; }

.ag-font-style {
  cursor: default;
  /* disable user mouse selection */
  user-select: none; }

.ag-layout-for-print {
  white-space: nowrap;
  display: inline-block; }

.ag-layout-normal {
  height: 100%; }

.ag-popup-backdrop {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%; }

.ag-header {
  white-space: nowrap;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%; }

.ag-layout-normal .ag-header {
  position: absolute;
  top: 0px;
  left: 0px; }

.ag-pinned-left-header {
  float: left;
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  height: 100%; }

.ag-pinned-right-header {
  float: right;
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  height: 100%; }

.ag-header-viewport {
  box-sizing: border-box;
  overflow: hidden;
  height: 100%; }

.ag-layout-normal .ag-header-row {
  position: absolute; }

.ag-layout-normal .ag-header-container {
  box-sizing: border-box;
  position: relative;
  white-space: nowrap;
  height: 100%; }

.ag-layout-auto-height .ag-header-row {
  position: absolute; }

.ag-layout-auto-height .ag-header-container {
  box-sizing: border-box;
  position: relative;
  white-space: nowrap;
  height: 100%; }

.ag-layout-for-print .ag-header-container {
  white-space: nowrap; }

.ag-header-overlay {
  display: block;
  position: absolute; }

.ag-header-cell {
  box-sizing: border-box;
  vertical-align: bottom;
  display: inline-block;
  height: 100%;
  position: absolute; }

.ag-floating-filter {
  box-sizing: border-box;
  position: absolute;
  display: inline-block; }

.ag-floating-filter-body {
  margin-right: 25px;
  height: 20px; }

.ag-floating-filter-full-body {
  width: 100%;
  height: 20px; }

.ag-floating-filter-input {
  width: 100%; }

.ag-floating-filter-input:read-only {
  background-color: #eeeeee; }

.ag-floating-filter-menu {
  position: absolute;
  user-select: none; }

.ag-dnd-ghost {
  font-size: 14px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: absolute;
  background: #e5e5e5;
  border: 1px solid black;
  cursor: move;
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 3px;
  line-height: 1.4; }

.ag-dnd-ghost-icon {
  display: inline-block;
  float: left;
  padding: 2px; }

.ag-dnd-ghost-label {
  display: inline-block; }

.ag-header-group-cell {
  height: 100%;
  display: inline-block;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  position: absolute; }

.ag-header-group-cell-label {
  text-overflow: ellipsis;
  overflow: hidden; }

.ag-header-cell-label {
  text-overflow: ellipsis;
  overflow: hidden; }

.ag-header-cell-resize {
  height: 100%;
  width: 4px;
  cursor: col-resize; }

.ag-ltr .ag-header-cell-resize {
  float: right; }

.ag-ltr .ag-pinned-right-header .ag-header-cell-resize {
  float: left; }

.ag-rtl .ag-header-cell-resize {
  float: left; }

.ag-rtl .ag-pinned-left-header .ag-header-cell-resize {
  float: right; }

.ag-ltr .ag-header-select-all {
  float: left; }

.ag-rtl .ag-header-select-all {
  float: right; }

.ag-header-expand-icon {
  padding-left: 4px; }

.ag-header-cell-menu-button {
  float: right; }

.ag-overlay-panel {
  display: table;
  width: 100%;
  height: 100%;
  pointer-events: none; }

.ag-overlay-wrapper {
  display: table-cell;
  vertical-align: middle;
  text-align: center; }

.ag-bl-overlay {
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px; }

.ag-bl-full-height {
  height: 100%;
  overflow: auto;
  position: relative; }

.ag-bl-west {
  float: left; }

.ag-bl-full-height-west {
  height: 100%; }

.ag-bl-east {
  float: right; }

.ag-bl-full-height-east {
  height: 100%; }

.ag-bl-full-height-center {
  height: 100%; }

.ag-bl-normal {
  height: 100%;
  position: relative; }

.ag-bl-normal-center-row {
  height: 100%;
  overflow: hidden; }

.ag-bl-normal-west {
  height: 100%;
  float: left; }

.ag-bl-normal-east {
  height: 100%;
  float: right; }

.ag-bl-normal-center {
  height: 100%; }

.ag-bl-dont-fill {
  position: relative; }

.ag-body {
  width: 100%;
  box-sizing: border-box; }

.ag-layout-normal .ag-body {
  height: 100%;
  position: absolute; }

.ag-floating-top {
  width: 100%;
  white-space: nowrap;
  box-sizing: border-box;
  overflow: hidden; }

.ag-layout-normal .ag-floating-top {
  position: absolute;
  left: 0px; }

.ag-pinned-left-floating-top {
  float: left;
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  position: relative; }

.ag-layout-normal .ag-pinned-left-floating-top {
  height: 100%; }

.ag-pinned-right-floating-top {
  float: right;
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  position: relative; }

.ag-layout-normal .ag-pinned-right-floating-top {
  height: 100%; }

.ag-floating-top-viewport {
  box-sizing: border-box;
  overflow: hidden; }

.ag-layout-normal .ag-floating-top-viewport {
  height: 100%; }

.ag-floating-top-container {
  box-sizing: border-box;
  position: relative;
  white-space: nowrap; }

.ag-floating-bottom {
  width: 100%;
  white-space: nowrap;
  box-sizing: border-box;
  overflow: hidden; }

.ag-layout-normal .ag-floating-bottom {
  position: absolute;
  left: 0px; }

.ag-pinned-left-floating-bottom {
  float: left;
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  position: relative; }

.ag-layout-normal .ag-pinned-left-floating-bottom {
  height: 100%; }

.ag-pinned-right-floating-bottom {
  float: right;
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  position: relative; }

.ag-layout-normal .ag-pinned-right-floating-bottom {
  height: 100%; }

.ag-floating-bottom-viewport {
  box-sizing: border-box;
  overflow: hidden; }

.ag-layout-normal .ag-floating-bottom-viewport {
  height: 100%; }

.ag-floating-bottom-container {
  box-sizing: border-box;
  position: relative;
  white-space: nowrap; }

.ag-pinned-left-cols-viewport {
  float: left; }

.ag-pinned-left-cols-container {
  display: inline-block;
  position: relative; }

.ag-pinned-right-cols-viewport {
  float: right; }

.ag-ltr .ag-pinned-right-cols-viewport {
  overflow-x: hidden;
  overflow-y: auto; }

.ag-ltr .ag-pinned-left-cols-viewport {
  overflow: hidden; }

.ag-rtl .ag-pinned-right-cols-viewport {
  overflow: hidden; }

.ag-rtl .ag-pinned-left-cols-viewport {
  overflow-x: hidden;
  overflow-y: auto; }

.ag-pinned-right-cols-container {
  display: inline-block;
  position: relative; }

.ag-layout-normal .ag-body-viewport-wrapper {
  height: 100%; }

.ag-body-viewport {
  overflow-x: auto;
  overflow-y: auto; }

.ag-layout-normal .ag-body-viewport {
  height: 100%; }

.ag-full-width-viewport {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: inline;
  pointer-events: none;
  box-sizing: border-box;
  overflow: hidden; }

.ag-full-width-container {
  overflow: hidden;
  position: relative;
  width: 100%; }

.ag-floating-bottom-full-width-container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: none;
  overflow: hidden;
  display: inline; }

.ag-floating-top-full-width-container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: none;
  overflow: hidden;
  display: inline; }

.ag-full-width-row {
  pointer-events: all;
  overflow: hidden; }

.ag-layout-normal .ag-body-container {
  position: relative;
  display: inline-block; }

.ag-layout-auto-height .ag-body-container {
  position: relative;
  display: inline-block; }

.ag-row-animation {
  transition: top 0.4s, height 0.4s, background-color 0.1s, opacity 0.2s; }

.ag-row-no-animation {
  transition: background-color 0.1s; }

.ag-row {
  box-sizing: border-box; }

.ag-layout-normal .ag-row {
  white-space: nowrap;
  position: absolute;
  width: 100%; }

.ag-layout-auto-height .ag-row {
  white-space: nowrap;
  position: relative;
  width: 100%; }

.ag-layout-for-print .ag-row {
  position: relative; }

.ag-column-moving .ag-cell {
  transition: left 0.2s; }

.ag-column-moving .ag-header-cell {
  transition: left 0.2s; }

.ag-column-moving .ag-header-group-cell {
  transition: left 0.2s, width 0.2s; }

.ag-column-drop {
  width: 100%;
  box-sizing: border-box; }

.ag-column-drop-vertical .ag-column-drop-cell {
  display: block; }

.ag-column-drop-vertical .ag-column-drop-empty-message {
  display: block; }

.ag-column-drop-vertical .ag-column-drop-cell-button {
  line-height: 16px; }

.ag-ltr .ag-column-drop-vertical .ag-column-drop-cell-button {
  float: right; }

.ag-rtl .ag-column-drop-vertical .ag-column-drop-cell-button {
  float: left; }

.ag-column-drop-horizontal {
  white-space: nowrap; }
  .ag-column-drop-horizontal .ag-column-drop-cell {
    display: inline-block; }
  .ag-column-drop-horizontal .ag-column-drop-empty-message {
    display: inline-block; }

.ag-cell {
  display: inline-block;
  white-space: nowrap;
  height: 100%;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  position: absolute; }

.ag-value-slide-out {
  opacity: 1.0;
  margin-right: 5px;
  transition: opacity 3s, margin-right 3s;
  transition-timing-function: linear; }

.ag-value-slide-out-end {
  opacity: 0.0;
  margin-right: 10px; }

.ag-opacity-zero {
  opacity: 0.0; }

.ag-cell-edit-input {
  width: 100%;
  height: 100%; }

.ag-group-cell-entire-row {
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  height: 100%;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden; }

.ag-footer-cell-entire-row {
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  height: 100%;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden; }

.ag-large .ag-root {
  font-size: 20px; }

.ag-popup-editor {
  position: absolute;
  user-select: none; }

.ag-menu {
  position: absolute;
  user-select: none; }

.ag-menu-column-select-wrapper {
  width: 200px;
  height: 300px;
  overflow: auto; }

.ag-menu-list {
  display: table;
  border-collapse: collapse; }

.ag-menu-option {
  display: table-row; }

.ag-menu-option-text {
  display: table-cell; }

.ag-menu-option-shortcut {
  display: table-cell; }

.ag-menu-option-icon {
  display: table-cell; }

.ag-menu-option-popup-pointer {
  display: table-cell; }

.ag-menu-separator {
  display: table-row; }

.ag-menu-separator-cell {
  display: table-cell; }

.ag-virtual-list-viewport {
  overflow-x: auto;
  height: 100%;
  width: 100%; }

.ag-virtual-list-container {
  position: relative;
  overflow: hidden; }

.ag-rich-select {
  outline: none;
  cursor: default; }

.ag-rich-select-row {
  white-space: nowrap; }

.ag-rich-select-list {
  width: 200px;
  height: 200px; }

.ag-set-filter-list {
  width: 200px;
  height: 200px; }

.ag-set-filter-item {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

.ag-virtual-list-item {
  position: absolute;
  width: 100%; }

.ag-filter-filter {
  width: 100%; }

.ag-floating-filter-body input {
  width: 100%;
  margin: 0;
  height: 19px; }

.ag-floating-filter-full-body input {
  width: 100%;
  margin: 0;
  height: 19px; }

.ag-filter-select {
  width: 110px;
  margin: 4px 4px 0px 4px; }

.ag-list-selection {
  user-select: none;
  cursor: default; }

.ag-tool-panel {
  width: 200px;
  user-select: none;
  cursor: default;
  box-sizing: border-box;
  overflow: auto; }

.ag-layout-normal .ag-tool-panel {
  height: 100%; }

.ag-column-select-indent {
  display: inline-block; }

.ag-column-select-column {
  white-space: nowrap; }

.ag-ltr .ag-column-select-column {
  margin-left: 16px; }

.ag-rtl .ag-column-select-column {
  margin-right: 16px; }

.ag-column-select-column-group {
  white-space: nowrap; }

.ag-hidden {
  display: none !important; }

.ag-visibility-hidden {
  visibility: hidden !important; }

.ag-faded {
  opacity: 0.3; }

.ag-width-half {
  width: 50%;
  display: inline-block; }

.ag-shake-left-to-right {
  animation-name: ag-shake-left-to-right;
  animation-duration: 0.2s;
  animation-iteration-count: infinite;
  animation-direction: alternate; }

@keyframes ag-shake-left-to-right {
  from {
    padding-left: 6px;
    padding-right: 2px; }
  to {
    padding-left: 2px;
    padding-right: 6px; } }

/* icons are used outside of the grid root (in the ghost) */
.ag-icon-aggregation {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/aggregation.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-arrows {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/arrows.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-asc {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/asc.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-checkbox-checked-readonly {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/checkbox-checked-readonly.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-checkbox-checked {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/checkbox-checked.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-checkbox-indeterminate-readonly {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/checkbox-indeterminate-readonly.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-checkbox-indeterminate {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/checkbox-indeterminate.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-checkbox-unchecked-readonly {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/checkbox-unchecked-readonly.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-checkbox-unchecked {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/checkbox-unchecked.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-column {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/column.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-columns {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/columns.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-contracted {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/contracted.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-copy {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/copy.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-cut {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/cut.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-desc {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/desc.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-expanded {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/expanded.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-eye-slash {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/eye-slash.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-eye {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/eye.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-filter {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/filter.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-folder-open {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/folder-open.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-folder {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/folder.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-group {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/group.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-indeterminate {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/indeterminate.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-left {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/left.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-loading {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/loading.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-menu {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/menu.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-minus {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/minus.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-none {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/none.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-not-allowed {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/not-allowed.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-paste {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/paste.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-pin {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/pin.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-pivot {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/pivot.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-plus {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/plus.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-right {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/right.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-small-left {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/small-left.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-small-right {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/small-right.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-small-up {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/small-up.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-small-down {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/small-down.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-tick {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/tick.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-cross {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/cross.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-tree-open {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/tree-open.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

.ag-icon-tree-closed {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/tree-closed.svg") center no-repeat;
  background-size: 12px 12px;
  filter: "initial"; }

/*
- todo {
- loading overlay colors {
- rich select colors {
 */
.ag-icon:not(.ag-faded) {
  opacity: 0.8; }

.ag-fresh {
  line-height: 1.4;
  font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto";
  font-size: 12px;
  color: #444;
  /* this is for the rowGroupPanel, that appears along the top of the grid */
  /* this is for the column drops that appear in the toolPanel */ }
  .ag-fresh .ag-numeric-cell {
    text-align: right; }
  .ag-fresh .ag-header-cell-label {
    display: flex; }
    .ag-fresh .ag-header-cell-label > span {
      float: left; }
    .ag-fresh .ag-header-cell-label .ag-header-icon {
      margin-top: 2px; }
    .ag-fresh .ag-header-cell-label .ag-header-cell-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  .ag-fresh .ag-numeric-header .ag-header-cell-label {
    flex-direction: row-reverse; }
  .ag-fresh .ag-numeric-header .ag-header-cell-menu-button {
    float: left; }
  .ag-fresh .ag-numeric-header .ag-header-cell-label {
    width: calc(100% - 12px);
    float: right; }
    .ag-fresh .ag-numeric-header .ag-header-cell-label > span {
      float: right; }
  .ag-fresh .ag-header-cell-resize {
    position: absolute;
    right: 0; }
  .ag-fresh .ag-rtl .ag-header-cell-resize {
    position: absolute;
    left: 0;
    right: auto; }
  .ag-fresh img {
    vertical-align: middle;
    border: 0; }
  .ag-fresh .ag-root {
    border: 1px solid #ddd; }
  .ag-fresh .ag-cell-data-changed {
    background-color: #cec; }
  .ag-fresh .ag-cell-data-changed-animation {
    background-color: transparent;
    transition: background-color 1s; }
  .ag-fresh .ag-cell-not-inline-editing {
    padding: 8px;
    /* compensate for the transparent borders; */
    padding-left: 7px; }
  .ag-fresh .ag-cell-range-selected-1:not(.ag-cell-focus) {
    background-color: rgba(120, 120, 120, 0.4); }
  .ag-fresh .ag-cell-range-selected-2:not(.ag-cell-focus) {
    background-color: rgba(80, 80, 80, 0.4); }
  .ag-fresh .ag-cell-range-selected-3:not(.ag-cell-focus) {
    background-color: rgba(40, 40, 40, 0.4); }
  .ag-fresh .ag-cell-range-selected-4:not(.ag-cell-focus) {
    background-color: rgba(0, 0, 0, 0.4); }
  .ag-fresh .ag-cell-focus {
    border: 1px solid #ddd; }
  .ag-fresh .ag-cell-no-focus {
    border-top: none;
    border-bottom: 1px solid #ddd; }
  .ag-fresh .ag-ltr .ag-cell-no-focus {
    border-right: 1px solid #ddd;
    border-left: none; }
  .ag-fresh .ag-rtl .ag-cell-no-focus {
    border-right: 1px solid #ddd;
    border-left: none; }
  .ag-fresh .ag-rtl .ag-cell-first-right-pinned {
    border-left: 1px solid #ddd; }
  .ag-fresh .ag-ltr .ag-cell-first-right-pinned {
    border-left: 1px solid #ddd; }
  .ag-fresh .ag-rtl .ag-cell-last-left-pinned {
    border-right: 1px solid #ddd; }
  .ag-fresh .ag-ltr .ag-cell-last-left-pinned {
    border-right: 1px solid #ddd; }
  .ag-fresh .ag-cell-highlight {
    border: 1px solid darkgreen; }
  .ag-fresh .ag-cell-highlight-animation {
    transition: border 1s; }
  .ag-fresh .ag-value-change-delta {
    padding-right: 2px; }
  .ag-fresh .ag-value-change-delta-up {
    color: darkgreen; }
  .ag-fresh .ag-value-change-delta-down {
    color: darkred; }
  .ag-fresh .ag-value-change-value {
    background-color: transparent;
    border-radius: 1px;
    padding-left: 1px;
    padding-right: 1px;
    transition: background-color 1s; }
  .ag-fresh .ag-value-change-value-highlight {
    background-color: #cec;
    transition: background-color 0.1s; }
  .ag-fresh .ag-rich-select {
    font-size: 14px;
    border: 1px solid #ddd;
    background-color: white; }
  .ag-fresh .ag-rich-select-value {
    padding: 2px; }
  .ag-fresh .ag-rich-select-list {
    border-top: 1px solid #ddd; }
  .ag-fresh .ag-rich-select-row {
    padding: 2px; }
  .ag-fresh .ag-rich-select-row-selected {
    background-color: #BDE2E5; }
  .ag-fresh .ag-large-text {
    border: 1px solid #ddd; }
  .ag-fresh .ag-header-select-all, .ag-fresh .ag-header-cell-menu-button {
    margin-top: 3px;
    line-height: 1rem; }
  .ag-fresh .ag-header-select-all {
    padding-right: 4px; }
  .ag-fresh .ag-filter-header-container > label {
    margin-bottom: 0; }
  .ag-fresh .ag-header-cell {
    padding: 2px;
    padding-top: 4px; }
  .ag-fresh .ag-header {
    color: #444;
    background: #f1f1f1;
    border-bottom: 1px solid #ddd;
    font-weight: normal; }
  .ag-fresh .ag-header-icon {
    color: #444;
    stroke: none;
    fill: #444; }
  .ag-fresh .ag-filter-icon {
    display: inline-block; }
  .ag-fresh .ag-sort-ascending-icon:empty {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/asc.svg") center no-repeat;
    background-size: 12px 12px;
    filter: "initial"; }
  .ag-fresh .ag-sort-descending-icon:empty {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/desc.svg") center no-repeat;
    background-size: 12px 12px;
    filter: "initial"; }
  .ag-fresh .ag-sort-none-icon:empty {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/none.svg") center no-repeat;
    background-size: 12px 12px;
    filter: "initial"; }
  .ag-fresh .ag-layout-for-print .ag-header-container {
    background: #f1f1f1;
    border-bottom: 1px solid #ddd; }
  .ag-fresh .ag-ltr .ag-header-cell {
    border-right: 1px solid #ddd; }
  .ag-fresh .ag-rtl .ag-header-cell {
    border-left: 1px solid #ddd; }
  .ag-fresh .ag-header-cell-moving .ag-header-cell-label {
    opacity: 0.5; }
  .ag-fresh .ag-header-cell-moving {
    background-color: #bebebe; }
  .ag-fresh .ag-ltr .ag-header-group-cell {
    border-right: 1px solid #ddd; }
  .ag-fresh .ag-rtl .ag-header-group-cell {
    border-left: 1px solid #ddd; }
  .ag-fresh .ag-header-group-cell-with-group {
    border-bottom: 1px solid #ddd; }
  .ag-fresh .ag-header-group-cell-label {
    padding: 2px;
    padding-top: 4px; }
  .ag-fresh .ag-rtl .ag-header-group-text {
    margin-left: 2px; }
  .ag-fresh .ag-ltr .ag-header-group-text {
    margin-right: 2px; }
  .ag-fresh .ag-header-cell-menu-button:empty {
    width: 12px;
    height: 12px;
    background: transparent url("/ReactUI/node_modules/ag-grid/src/styles/icons/menu.svg") center no-repeat;
    background-size: 12px 12px;
    filter: "initial"; }
  .ag-fresh .ag-ltr .ag-pinned-right-header {
    border-left: 1px solid #ddd; }
  .ag-fresh .ag-rtl .ag-pinned-left-header {
    border-right: 1px solid #ddd; }
  .ag-fresh .ag-body {
    background-color: white; }
  .ag-fresh .ag-row-odd {
    background-color: white; }
  .ag-fresh .ag-row-even {
    background-color: white; }
  .ag-fresh .ag-row-selected {
    background-color: #dcf8ff; }
  .ag-fresh .ag-row-stub {
    background-color: #f0f0f0; }
  .ag-fresh .ag-stub-cell {
    padding: 2px 2px 2px 10px; }
  .ag-fresh .ag-floating-top {
    background-color: #f0f0f0; }
  .ag-fresh .ag-floating-top .ag-row {
    background-color: #f0f0f0; }
  .ag-fresh .ag-floating-bottom {
    background-color: #f0f0f0; }
  .ag-fresh .ag-floating-bottom .ag-row {
    background-color: #f0f0f0; }
  .ag-fresh .ag-overlay-loading-wrapper {
    background-color: rgba(255, 255, 255, 0.5); }
  .ag-fresh .ag-overlay-loading-center {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    color: black; }
  .ag-fresh .ag-overlay-no-rows-center {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px; }
  .ag-fresh .ag-group-cell-entire-row {
    background-color: white;
    padding: 8px; }
  .ag-fresh .ag-footer-cell-entire-row {
    background-color: white;
    padding: 8px; }
  .ag-fresh .ag-group-cell {
    font-style: italic; }
  .ag-fresh .ag-ltr .ag-group-expanded {
    padding-right: 4px; }
  .ag-fresh .ag-rtl .ag-group-expanded {
    padding-left: 4px; }
  .ag-fresh .ag-ltr .ag-group-contracted {
    padding-right: 4px; }
  .ag-fresh .ag-rtl .ag-group-contracted {
    padding-left: 4px; }
  .ag-fresh .ag-ltr .ag-group-loading {
    padding-right: 4px; }
  .ag-fresh .ag-rtl .ag-group-loading {
    padding-left: 4px; }
  .ag-fresh .ag-ltr .ag-group-value {
    padding-right: 2px; }
  .ag-fresh .ag-rtl .ag-group-value {
    padding-left: 2px; }
  .ag-fresh .ag-ltr .ag-group-checkbox {
    padding-right: 2px; }
  .ag-fresh .ag-rtl .ag-group-checkbox {
    padding-left: 2px; }
  .ag-fresh .ag-group-child-count {
    display: inline-block; }
  .ag-fresh .ag-footer-cell {
    font-style: italic; }
  .ag-fresh .ag-menu {
    border: 1px solid #808080;
    background-color: white;
    cursor: default;
    font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto";
    font-size: 12px; }
    .ag-fresh .ag-menu .ag-tab-header {
      background-color: #e6e6e6; }
    .ag-fresh .ag-menu .ag-tab {
      padding: 6px 8px 6px 8px;
      margin: 2px 2px 0px 2px;
      display: inline-block;
      border-right: 1px solid transparent;
      border-left: 1px solid transparent;
      border-top: 1px solid transparent;
      border-top-right-radius: 2px;
      border-top-left-radius: 2px; }
    .ag-fresh .ag-menu .ag-tab-selected {
      background-color: #f6f6f6;
      border-right: 1px solid #d3d3d3;
      border-left: 1px solid #d3d3d3;
      border-top: 1px solid #d3d3d3; }
  .ag-fresh .ag-menu-separator {
    border-top: 1px solid #ddd; }
  .ag-fresh .ag-menu-option-active {
    background-color: #BDE2E5; }
  .ag-fresh .ag-menu-option-icon {
    padding: 2px 4px 2px 4px;
    vertical-align: middle; }
  .ag-fresh .ag-menu-option-text {
    padding: 2px 4px 2px 4px;
    vertical-align: middle; }
  .ag-fresh .ag-menu-option-shortcut {
    padding: 2px 2px 2px 2px;
    vertical-align: middle; }
  .ag-fresh .ag-menu-option-popup-pointer {
    padding: 2px 4px 2px 4px;
    vertical-align: middle;
    display: table-cell; }
  .ag-fresh .ag-menu-option-disabled {
    opacity: 0.5; }
  .ag-fresh .ag-menu-column-select-wrapper {
    margin: 2px; }
  .ag-fresh .ag-filter-checkbox {
    margin-right: 4px;
    margin-bottom: 0;
    display: inline-block; }
  .ag-fresh .ag-filter-header-container {
    padding: 2px 4px 2px 4px;
    border-bottom: 1px solid #ddd; }
  .ag-fresh .ag-filter-apply-panel {
    border-top: 1px solid #ddd;
    padding: 2px 0px 2px 4px; }
  .ag-fresh .ag-virtual-list-container {
    padding: 4px 4px 10px 4px; }
  .ag-fresh .ag-ltr .ag-selection-checkbox {
    padding-right: 4px; }
  .ag-fresh .ag-rtl .ag-selection-checkbox {
    padding-left: 4px; }
  .ag-fresh .ag-paging-panel {
    padding: 4px; }
  .ag-fresh .ag-paging-button {
    margin-left: 4px;
    margin-right: 4px; }
  .ag-fresh .ag-paging-row-summary-panel {
    display: inline-block;
    width: 300px; }
  .ag-fresh .ag-tool-panel {
    background-color: white;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    color: #444; }
  .ag-fresh .ltr .ag-tool-panel {
    border-right: 1px solid #ddd; }
  .ag-fresh .rtl .ag-tool-panel {
    border-left: 1px solid #ddd; }
  .ag-fresh .ag-status-bar {
    color: #444;
    background-color: white;
    font-size: 12px;
    height: 22px;
    border-bottom: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    padding: 2px; }
  .ag-fresh .ag-status-bar-aggregations {
    float: right; }
  .ag-fresh .ag-status-bar-item {
    padding-left: 10px; }
  .ag-fresh .ag-column-drop-cell {
    background: #1397f4;
    color: white;
    border: 1px solid #808080; }
  .ag-fresh .ag-column-drop-cell-ghost {
    opacity: 0.5; }
  .ag-fresh .ag-column-drop-cell-text {
    padding-left: 2px;
    padding-right: 2px; }
  .ag-fresh .ag-column-drop-cell-button {
    border: 1px solid transparent;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 3px; }
  .ag-fresh .ag-column-drop-cell-button:hover {
    border: 1px solid #ddd; }
  .ag-fresh .ag-column-drop-empty-message {
    padding-left: 2px;
    padding-right: 2px;
    color: grey; }
  .ag-fresh .ag-column-drop-icon {
    margin: 6px 3px 0px 3px; }
  .ag-fresh .ag-column-drop {
    background-color: white; }
  .ag-fresh .ag-column-drop-horizontal {
    padding: 2px;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd; }
  .ag-fresh .ag-column-drop-vertical {
    padding: 4px 4px 10px 4px;
    border-bottom: 1px solid #ddd;
    overflow: auto; }
    .ag-fresh .ag-column-drop-vertical .ag-column-drop-cell {
      margin-top: 2px; }
    .ag-fresh .ag-column-drop-vertical .ag-column-drop-empty-message {
      padding: 5px; }
  .ag-fresh .ag-pivot-mode {
    border-bottom: 1px solid #ddd;
    padding: 2px 4px 3px 4px;
    background-color: white; }
  .ag-fresh .ag-tool-panel .ag-column-select-panel {
    padding: 4px 4px 10px 4px;
    padding-left: 0;
    border-bottom: 1px solid #ddd;
    overflow: auto; }
  .ag-fresh .ag-select-agg-func-popup {
    cursor: default;
    position: absolute;
    font-size: 14px;
    background-color: white;
    border: 1px solid #ddd; }
  .ag-fresh .ag-select-agg-func-item {
    padding-left: 2px;
    padding-right: 2px; }
  .ag-fresh .ag-select-agg-func-item:hover {
    background-color: #BDE2E5; }
  .ag-fresh .ag-floating-filter-body {
    margin-right: 20px;
    width: calc(100% - 20px); }
  .ag-fresh .ag-floating-filter-button {
    margin-top: -20px;
    display: inline-block;
    float: right; }
    .ag-fresh .ag-floating-filter-button button {
      border: 0;
      background: transparent;
      padding: 3px;
      margin: 0; }
  .ag-fresh .ag-rtl .ag-floating-filter-body {
    margin-right: 0;
    margin-left: 20px;
    float: right; }
  .ag-fresh .ag-rtl .ag-floating-filter-button {
    float: left; }
  .ag-fresh .ag-sort-order {
    margin-left: .5em;
    font-size: 0.80em; }
    .ag-fresh .ag-sort-order::before {
      content: '('; }
    .ag-fresh .ag-sort-order::after {
      content: ')'; }

.ag-header-cell.ag-header-cell {
  padding: 8px; }

.ag-cell-focus {
  background-color: #dcf8ff; }

.ag-react-container {
  height: 100%;
  width: 100%; }

.ag-fresh .ag-cell-not-inline-editing {
  padding-left: 8px; }
