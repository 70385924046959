body {
  width: 100%;
  height: 100%;
}

input, textarea {
  background: rgb(255,255,255);
}

#root {
  height: 100%;
}

.aur-application-wrapper {
  height: 100%;
}

.aur-button {
  width: 100%;
  cursor: pointer;
}

.application-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.application-bg > img {
  position: absolute;
  left: 0;
  top: 0;
  transition: opacity 1s ease-in-out;
  z-index: 0;
}

.login-layout-splash,
.login-layout-middle {
  height: 100%;
}

.login-layout-middle-main {
  padding: 20px;
  margin: auto;
  background: rgba(250, 250, 250, 0.90);
  position: relative;
  overflow: auto;
}

.login-layout-offset {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.login-layout-offset-left {
  height: 100%;
  width: calc(100% - 600px);
  z-index: 2;
  background: none;
}

@media(min-aspect-ratio: 16/9) {
  .application-bg > img {
    width: 100%;
    height: auto;
  }
}

@media(max-aspect-ratio: 16/9) {
  .application-bg > img {
    height: 100%;
    width: auto;
  }
}

@media(max-width: 1200px) {
  .login-layout-offset-left {
    visibility: hidden;
    width: calc(50% - 210px);
  }
}

@media(max-width: 420px) {
  .login-layout-offset-right {
    width: 100%;
  }
  .login-layout-offset-right-body {
    width: 100%;
  }
  .application-footer {
    visibility: hidden;
  }
  .login-layout-middle-main {
    height: 100%;
  }
}

@media(min-width: 420px) {
  .login-layout-offset-right {
    width: 420px;
  }
  .login-layout-offset-right-body {
    width: 420px;
  }
  .login-layout-middle-main {
    height: calc(100% - 45px);
  }
}

@media(max-width: 800px) {
  .login-layout-middle-main {
    width: 100%;
  }
  .applicationCell {
    width: 100%;
  }
}

@media(min-width: 800px) {
  .login-layout-middle-main {
    width: 800px;
  }
  .applicationCell {
    width: 50%;
  }
}

.login-layout-offset-right {
  height: 100%;
}

.login-layout-offset-right-body {
  height: 100%;
  background-color: rgba(250, 250, 250, 0.90);
  box-sizing: border-box;
  position: fixed;
  overflow: auto;
}

.login-layout-splash-main {
  height: 100%;
  position: relative;
}

#pageContainer {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 20px;
  box-sizing: border-box;
}

#pageContainer form > button:not(:first-of-type) {
  margin-top: 16px;
}

.input-icon-container {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.input-icon-container .input-icon {
  position: absolute;
  padding: 10px;
  pointer-events: none;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .input-icon-container .input-icon {
    margin-top: -16px;
  }
}

.input-icon-container input {
  padding-left: 30px;
}

.aur-text-box {
  height: auto;
}

.applicationCell {
  padding: 5px;
  display: inline-block;
  vertical-align: top;
}

.accountApplicationStateSelect {
  width: 40%;
  padding-right: 5px;
  display: inline-block;
  vertical-align: top;
}

.accountApplicationZipCode {
  width: 60%;
  padding-left: 5px;
  display: inline-block;
  vertical-align: top;
}

.accountApplicationBtns {
  text-align: right;
}

.accountApplicationBtns > .aur-button {
  width: 33%;
  margin-left: 20px;
}

.logo_container {
  text-align: center;
  margin-bottom: 16px;
}

.logo_container img {
  width: 180px;
  margin-top: 60px;
}

.splashContainer {
  width: 600px;
  min-height: 200px;
  position: absolute;
  top: 100px;
  left: calc(50% - 300px);
  background: rgba(250,250,250,0.90);
  padding: 24px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  line-height: 1.5;
}

.splashContainer button {
  margin-top: 24px;
}

.splashContainer h1 {
  font-size: 1.5em;
}

.splashContainer h3 {
  font-size: 1.2em;
}

.message-box {
  width: 600px;
  min-height: 200px;
  margin-left: calc(50% - 300px);
  margin-top: 100px;
  padding: 24px;
  box-sizing: border-box;
  background: rgba(250,250,250,0.90);
  border: 1px solid #ddd;
}

.message-box .message-box__header {
  text-transform: uppercase;
  border-bottom: 1px solid #ddd;
}

.message-box .message-box__header h4 {
  margin-bottom: 4px;
  margin-top: 4px;
}

.message-box .message-box__body {
  padding-top: 24px;
}

.login_info_messages {
  border: 1px solid rgba(19, 151, 244, 0.2);
  background: rgba(150, 160, 189, 0.2);
  color: #444444;
  padding: 10px;
  border-radius: 2px;
  margin-bottom: 20px;
  line-height: 1.5;
}

.login_form_errors {
  border: 1px solid rgba(255, 0, 0, 0.2);
  background: rgba(189, 160, 150, 0.2);
  color: rgba(255, 0, 0, 0.8);
  padding: 10px;
  border-radius: 2px;
  margin-bottom: 20px;
  line-height: 1.5;
}

.login_info_div,
.login_error_div {
  margin: 2px;
}

.login_info_div > span,
.login_error_div > span {
  margin-right: 6px;
}

.aur-application-wrapper h4 {
  margin: 20px 0 20px 0;
}

#nav_links {
  margin-top: 40px;
}

#nav_links > li {
  margin: 20px 0 20px 0;
}

.rememberme-container {
  margin: 20px 0 20px 0;
}

.hintQuestion {
  margin: 5px 0 5px 0;
  background: rgba(250,250,250,0.90);
  border: 1px solid #ddd;
  padding: 10px;
}

.application-footer {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  background: rgb(68,68,68);
  width: 100%;
  height: 45px;
  color: rgb(200,200,200);
  z-index: 3;
}

.application-footer > div {
  display: inline-block;
  vertical-align: middle;
}

.application-footer > div:not(:first-child) {
  margin: auto;
  text-align: center;
}

.footer-logo {
  text-align: left;
}

.footer-logo-img {
  height: 22px;
  margin-top: 5px;
  margin-left: 10px;
}

.footer-link {
  color: rgb(200,200,200);
  text-decoration: none;
}

button:disabled {
  opacity: 0.5;
}

.loaderContainer {
  background: rgba(150, 160, 189, 0.5);
  position: absolute;
  height: 100%;
  width: 100%;
  margin: -20px;
}

.loaderContainer > .loader {
  margin-top: calc(50% - 40px);
  margin-left: calc(50% - 40px);
}

.loader {
  border: 12px solid #f3f3f3;
  border-top: 12px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.select-error > .Select-control {
  border-color: #f83155;
  background-color: #fff5f7;
}

.sso-separator {
  margin: 26px 0;
}
.aur-text{
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: #1397f4;
  color: white;
  font-weight: bold;
  margin-bottom: 10px;
}